<template>
  <a-modal
    title="用户详情"
    :footer="null"
    :visible="visible"
    width="700px"
    :destroyOnClose="true"
    @cancel="$emit('cancel')"
  >
    <a-form-model
      class="formInfo"
      ref="registerForm"
      layout="horizontal"
      :model="registerForm"
      labelAlign="left"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="姓名" prop="userName">
        <a-input
          v-model="registerForm.userName"
          placeholder="姓名"
          :disabled="readonly"
        />
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="phoneNumber">
        <a-input
          v-model="registerForm.phoneNumber"
          placeholder="手机号"
          :disabled="readonly"
        />
      </a-form-model-item>
      <a-form-model-item label="默认公司">
        <a-input
          v-model="companyName"
          placeholder="默认公司"
          :disabled="readonly"
        />
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="showOrder">
        <a-input
          v-model="registerForm.email"
          placeholder="邮箱"
          :disabled="readonly"
        />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 24 }">
        <div>
          <p>关联公司</p>
          <a-divider />
          <RelationCompany ref="relationCompany" :userId="companyCtr" />
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import RelationCompany from "./RelationCompany.vue";
import saasAdmin from "/src/api/saasAdmin.js";
const { getUserCompanyName } = saasAdmin.user;
export default {
  components: { RelationCompany },
  props: {
    from: { type: Object, default: () => {} },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      registerForm: {
        userName: "",
      },
      companyCtr: "",
      companyName: "",
      readonly: true,
    };
  },
  watch: {
    from: {
      handler() {
        this.registerForm = this.from;
        this.companyCtr = this.registerForm.id;
        if (this.companyCtr) {
          getUserCompanyName(this.registerForm.id).then((res) => {
            this.companyName = res === "" ? res : res.name;
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
