<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="datasource"
      rowKey="tenantId"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="invitedState" slot-scope="text, record">
        <p v-if="record.invitedState == 1">已同意</p>
        <p v-if="record.invitedState == 2">待处理</p>
        <p v-if="record.invitedState == 3">已拒绝</p>
      </template>
    </a-table>
  </div>
</template>

<script>
/**数据字典列表列配置 */
const columns = [
  {
    title: "公司名称",
    dataIndex: "name",
  },
  {
    title: "角色",
    dataIndex: "companyUserRoleName",
  },
  {
    title: "状态",
    dataIndex: "invitedState",
    align: "center",
    scopedSlots: { customRender: "invitedState" },
  },
];

import { getUserCompanyList } from "/src/api/companyuser.js";
export default {
  props: {
    userId: { type: String, default: () => "" },
  },
  data() {
    return {
      columns, //关联公司列表列
      datasource: [], //关联公司列表数据源
      currentUserId: "",
      /** 关联公司列表分页参数设置 */
      pagination: {
        pageSize: 4,
        total: 0,
        current: 1,
      },
    };
  },
  methods: {
    /**
     * 关联公司列表分页数变更事件
     * @param {object}  pagination [分页器事件]
     * */
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination ? pagination.current : pager.current;
      this.pagination = pager;
      this.getUserCompanyList();
    },
    /**
     * 设置关联公司列表数据源
     */
    getUserCompanyList() {
      var param = {};
      param.userId = this.currentUserId;
      param.skipCount =
        (this.pagination.current - 1) * this.pagination.pageSize;
      param.maxResultCount = this.pagination.pageSize;
      getUserCompanyList(param).then((res) => {
        this.datasource = res.items;
      });
    },
  },
  watch: {
    userId: {
      handler() {
        this.currentUserId = this.userId;
        if (this.currentUserId !== "") this.getUserCompanyList();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
