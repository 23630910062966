import { getRequest } from "@/utils/http";

/** 查询硬件所有用户集合 */
export const getUserListAsync = (parm) =>
  getRequest(`/identity/SaasAdminUser/list`, parm);

/**
 * 获取用户默认公司
 * @param {guid} id 用户ID
 * @returns
 */
export const getUserCompanyName = (id) =>
  getRequest(`/identity/SaasAdminUser/getusercompanyname/${id}`);

export default {
  getUserListAsync,
  getUserCompanyName,
};
