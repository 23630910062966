<template>
  <div class="bgStyle">
    <div>用户管理</div>
    <div>
      <a-space size="8">
        <a-input-search
          placeholder="姓名、手机号"
          style="width: 200px"
          @search="onSearch"
        />
      </a-space>
    </div>
    <div>
      <a-table
        :columns="columns"
        :data-source="datasource"
        rowKey="id"
        :pagination="pagination"
        @change="handleTableChange"
        :loading="loading"
      >
        <template slot="action" slot-scope="text, record">
          <a @click="onget(record)">查看</a>
        </template>
        <template slot="creationTime" slot-scope="text">
          {{ text | filterDate }}
        </template>
      </a-table>
    </div>
    <user-detail
      ref="userdetail"
      :from="detailCtr.from"
      :visible="detailCtr.visible"
      @cancel="
        () => {
          this.detailCtr.visible = false;
        }
      "
    ></user-detail>
  </div>
</template>

<script>
import UserDetail from "./components/UserDetail";
import saasAdmin from "/src/api/saasAdmin.js";
const { getUserListAsync } = saasAdmin.user;
/**用户管理列表列配置 */
const columns = [
  {
    title: "姓名",
    dataIndex: "userName",
  },
  {
    title: "手机号",
    dataIndex: "phoneNumber",
  },
  {
    title: "邮箱",
    dataIndex: "email",
  },
  {
    title: "创建时间",
    dataIndex: "creationTime",
    scopedSlots: { customRender: "creationTime" },
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  components: { UserDetail },
  data() {
    return {
      columns, //用户管理列表列
      datasource: [], //用户管理列表数据源
      /** 用户管理列表分页参数设置 */
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
      detailCtr: {
        from: {},
        visible: false,
      },
      loading: false,
    };
  },
  filters: {
    filterDate(val) {
      if (val) {
        let dt = val.substring(0, 19).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
  },
  /** 组件生命周期创建前事件 */
  created() {
    this.loading = true;
    getUserListAsync()
      .then((res) => {
        this.pagination.total = res.totalCount;
        this.datasource = res.items;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.$message.error("用户列表查询异常");
      });
  },
  methods: {
    /**
     * 姓名/手机号查询框查询事件
     * @param {string} value [用户名称或手机号]
     */
    onSearch(value) {
      var parm = {
        filter: value,
      };
      this.loading = true;
      getUserListAsync(parm)
        .then((res) => {
          this.pagination.total = res.totalCount;
          this.datasource = res.items;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("用户列表查询异常");
        });
    },
    /**
     * 用户管理列表行按钮（查看）单击事件
     * @param {Object} row [用户管理列表单击行]
     */
    onget(row) {
      this.detailCtr.from = row;
      this.detailCtr.visible = true;
    },
    /**
     * 用户管理列表页数变更事件
     * @param {Object} pagination [分页器对象]
     */
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination ? pagination.current : pager.current;
      this.pagination = pager;
      var parm = {
        skipCount: (this.pagination.current - 1) * this.pagination.pageSize,
      };
      this.loading = true;
      getUserListAsync(parm)
        .then((res) => {
          this.pagination.total = res.totalCount;
          this.datasource = res.items;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("用户列表查询异常");
        });
    },
  },
};
</script>
