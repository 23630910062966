import {
  postRequest,
  deleteRequest,
  getRequest,
  updateRequest,
} from "@/utils/http";

/** 创建通知模板 */
export const create = (params) =>
  postRequest(`/common/SaasAdminNotificationTpl`, params);
/** 根据id删除通知模板 */
export const deleteNotTpl = (id) =>
  deleteRequest(`/common/SaasAdminNotificationTpl/${id}`);
/** 根据id获取通知模板 */
export const getById = (id) =>
  getRequest(`/common/SaasAdminNotificationTpl/${id}`);
/** 根据条件获取通知模板列表 */
export const getList = (params = { maxResultCount: 10, skipCount: 0 }) =>
  getRequest(`/common/SaasAdminNotificationTpl/list`, params);
/** 更新通知模板 */
export const update = (id, params) =>
  updateRequest(`/common/SaasAdminNotificationTpl/${id}`, params);
/** 获取所有通知模板类型 */
export const getTemplateCategorys = () =>
  getRequest(`/common/SaasAdminNotificationTpl/getTemplateCategorys`);
/** 获取所有通知类型 */
export const getNotTypeEnums = () =>
  getRequest(`/common/SaasAdminNotificationTpl/getNotTypeEnums`);
/** 获取所有通知严重级别 */
export const getNotSeverityEnums = () =>
  getRequest(`/common/SaasAdminNotificationTpl/getNotSeverityEnums`);

/**获取所有通知名称 */
export const getAllNotificationNames = () =>
  getRequest(`/common/SaasAdminNotificationTpl/getAllNotificationNames`);

/**获取所有载体类型 */
export const getAllNotificationCarriers = () =>
  getRequest(`/common/SaasAdminNotificationTpl/carriers`);

export default {
  create,
  deleteNotTpl,
  getById,
  getList,
  update,
  getTemplateCategorys,
  getNotTypeEnums,
  getNotSeverityEnums,
  getAllNotificationNames,
  getAllNotificationCarriers,
};
