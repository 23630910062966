import {
  postRequest,
  updateRequest,
  deleteRequest,
  getRequest,
} from "@/utils/http";

/** 查询数据字典集合 */
export const getCodeList = (parm) =>
  getRequest(`/common/SaasAdminCode/list`, parm);
/**查询单条数据字典 */
export const getCode = (id) => getRequest(`/common/SaasAdminCode/${id}`);
/** 新建数据字典 */
export const createCode = (parm) => postRequest(`/common/SaasAdminCode`, parm);
/** 删除数据字典 */
export const deleteCode = (id) => deleteRequest(`/common/SaasAdminCode/${id}`);
/** 修改数据字典 */
export const updateCode = (id, parm) =>
  updateRequest(`/common/SaasAdminCode/${id}`, parm);
/**
 * 获取下拉数据
 * @param {Object} param 参数
 * @returns Promise
 */
export const getDowndownAsync = (
  param = {
    filter: "",
    maxResultCount: 100,
    sorting: "CodeName",
  }
) =>
  getRequest(
    `/common/SaasAdminCode/dropdown`,
    Object.assign(
      { filter: "", maxResultCount: 100, sorting: "CodeName" },
      param
    )
  );

export default {
  getCodeList,
  getCode,
  createCode,
  deleteCode,
  updateCode,
  getDowndownAsync,
};
